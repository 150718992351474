// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2BEi3su4bWV3wIGqkcwYd1 {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n\n.LBB5Bm5geAEkRzmv3_B8A {\n    font-family: monospace;\n    font-size: 12px;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}", "",{"version":3,"sources":["webpack://./src/web/form/mNotificationSummary.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[":local(.fixedTable) {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n\n:local(.bodyPreview) {\n    font-family: monospace;\n    font-size: 12px;\n    overflow-wrap: break-word;\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixedTable": "_2BEi3su4bWV3wIGqkcwYd1",
	"bodyPreview": "LBB5Bm5geAEkRzmv3_B8A"
};
export default ___CSS_LOADER_EXPORT___;
